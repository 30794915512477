.header {
    align-items: center;
    display: flex;
    height: 72px;
    justify-content: space-between;
    position: relative;
    width: 1296px;
}

.header .logo {
    align-items: center;
    display: flex;
    gap: 4px;
    position: relative;
    width: 247px;
}

.header .icon {
    height: 24px;
    position: relative;
    width: 24px;
}

.header .text {
    align-items: center;
    align-self: stretch;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    padding: 6px 0px 0px;
    position: relative;
}

.header .text-wrapper {
    color: #000000;
    font-family: "Gantari-SemiBold", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.header .menu {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 55px;
    position: relative;
}

.header .div-wrapper {
    align-items: center;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-color: #000000;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    padding: 6px 0px;
    position: relative;
}

.header .div {
    color: #000000;
    font-family: "Gantari-Bold", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -2.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.header .text-2 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    position: relative;
}

.header .text-wrapper-2 {
    color: #000000;
    font-family: "Gantari-Medium", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.header .login-phone {
    align-items: flex-start;
    background-color: #0056ff;
    border-radius: 24px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 24px;
    padding: 8px 12px;
    position: relative;
}

.header .login {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
}

.header .text-wrapper-3 {
    color: #ffffff;
    font-family: "Gantari-Medium", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}
