.hero {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    padding: 0px 0px 57px;
    position: relative;
}

.hero .img-form {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
}

.hero .image {
    background-color: #00000033;
    background-image: url(../assets/frame.png);
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 44px;
    height: 600px;
    position: relative;
    transform: rotate(180deg);
    width: 1296px;
}

.hero .text {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: 289px;
    justify-content: center;
    position: relative;
    top: 99px;
    transform: rotate(180deg);
    width: 1296px;
}

.hero .hassle-free-rentals {
    color: #ffffff;
    font-family: "Gantari-Bold", Helvetica;
    font-size: 60px;
    font-weight: 700;
    height: 84px;
    letter-spacing: 0;
    line-height: 60px;
    margin-left: -106.50px;
    margin-right: -106.50px;
    position: relative;
    text-align: center;
    width: 1509px;
}

.hero .div {
    -webkit-box-orient: vertical;
    /* -webkit-line-clamp: 2; */
    color: #ffffff;
    display: -webkit-box;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 16px;
    font-weight: 600;
    height: 54px;
    letter-spacing: 0;
    line-height: 20px;
    overflow: hidden;
    position: relative;
    text-align: center;
    text-overflow: ellipsis;
    width: 801px;
}

.hero .frame-327 {
    flex: 0 0 auto !important;
}
